import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import classNames from 'classnames/bind'
import PropTypes from 'prop-types'
import moment from 'moment'
import flaticon from 'asset/flaticon/flaticon.module.scss'
import styles from './accountingsTable.module.scss'
import {getConductAccountings, putConductAccountingsApprove} from 'redux/actions/conductAccountings'
import {DATE_FORMAT} from 'helper/constants'
import Table from './Table'
import endpoints from 'helper/endpoints'
import DownloadPdf from 'component/DownloadPdf'
import Loader from 'component/Loader'
import NumberFormat from 'react-number-format'
import InstallmentStatus from 'component/InstallmentStatus'

const cx = classNames.bind(styles)
const fi = classNames.bind(flaticon)

const AccountingsTable = (props) => {
  const {
    conductId,
    leaseId,
    accountings,
    accountingMeta,
    getConductAccountings,
    putConductAccountingsApprove,
    loading,
    dataReceived,
  } = props

  const [page, setPage] = useState(0)

  useEffect(() => {
    if (conductId && leaseId) {
      getConductAccountings(conductId, leaseId, page)
    }
  }, [getConductAccountings, conductId, leaseId, page])

  const fields = [
    {key: 'dates', title: 'Období', style: styles.columnDates},
    {key: 'tenant', title: 'Nájemce', style: styles.columnTenant},
    {key: 'amount_kind', title: 'Přepl. / Nedopl.'},
    {key: 'amount', title: 'Částka'},
    {key: 'pdf', title: ''},
    {key: 'approve', title: '', style: styles.columnApprove},
    {key: 'income_maturity_date', title: 'Splatnost'},
    {key: 'outcome_define_date', title: 'Odesláno'},
    {key: 'status', title: 'Stav'},
  ]

  const amountKindMapping = {
    undercharge: <span className={styles.undercharge}>Nedoplatek</span>,
    overcharge: <span className={styles.overcharge}>Přeplatek</span>,
  }

  const data =
    accountings &&
    accountings.map((item) => {
      return {
        ...item,
        dates:
          item.date_from &&
          item.date_to &&
          moment(item.date_from).format(DATE_FORMAT) +
            ' - ' +
            moment(item.date_to).format(DATE_FORMAT),
        tenant: item.tenants?.map((tenant) => tenant.name).join(', '),
        amount_kind: item.amount_kind && amountKindMapping[item.amount_kind],
        amount: (
          <NumberFormat
            thousandSeparator={' '}
            displayType="text"
            suffix={' Kč'}
            value={item.amount?.toLocaleString()}
          />
        ),
        pdf: item.is_downloadable ? (
          <DownloadPdf
            downloadUrl={`${endpoints.conducts}/${conductId}/leases/${leaseId}/settlements/${item.id}/download`}
            className={classNames(fi('flaticon-pdf-file'), cx('iconPdf'))}
          />
        ) : (
          ''
        ),
        approve:
          item.status === 'approved' ? (
            <div className={styles.approved}>Schváleno</div>
          ) :  (
            <div
              onClick={putConductAccountingsApprove.bind(null, conductId, leaseId, item.id)}
              className={styles.approveLink}
            >
              Schválit
            </div>
          ),
        income_maturity_date:
          item.income_installment?.maturity_date &&
          moment(item.income_installment.maturity_date).format(DATE_FORMAT),
        _key: item.id,
        outcome_define_date:
          item.outcome_installment?.define_date &&
          moment(item.outcome_installment.define_date).format(DATE_FORMAT),
        status: item.income_installment?.status && <InstallmentStatus status={item.income_installment.status} />,
      }
    })

  const handleNextPage = () => {
    if (accountingMeta.limit * (page + 1) < accountingMeta.total_count) {
      setPage((prevState) => prevState + 1)
    }
  }

  const handlePageClick = (page) => {
    if (page > 0 || accountingMeta.limit * (page + 1) < accountingMeta.total_count) {
      setPage(page)
    }
  }

  const handlePreviousPage = () => {
    if (page > 0) {
      setPage((prevState) => prevState - 1)
    }
  }

  return (
    <div className={styles.AccountingsTable}>
      {loading && !dataReceived ? (
        <Loader />
      ) : (
        <Table
          fields={fields}
          data={data}
          pagination={true}
          page={page}
          maxPage={Math.ceil(accountingMeta.total_count / accountingMeta.limit)}
          nextPage={handleNextPage}
          prevPage={handlePreviousPage}
          goToPage={handlePageClick}
        />
      )}
    </div>
  )
}

AccountingsTable.propTypes = {
  conductId: PropTypes.number,
  leaseId: PropTypes.number,
  accountings: PropTypes.array,
  accountingMeta: PropTypes.object,
  getConductAccountings: PropTypes.func,
  putConductAccountingsApprove: PropTypes.func,
  loading: PropTypes.bool,
  dataReceived: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  accountings: state.conductAccountings.data,
  accountingMeta: state.conductAccountings.meta,
  loading: state.conductAccountings.getInProgress,
  dataReceived: state.conductAccountings.dataReceived,
})

const mapDispatchToProps = {
  getConductAccountings,
  putConductAccountingsApprove,
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountingsTable)
