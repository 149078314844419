const resolveEnv = (envName) => {
  if (ENVIRONMENT === 'production') {
    return window._env_[envName]
  }
  return process.env[envName]
}

export const ENVIRONMENT = process.env.NODE_ENV
export const REACT_APP_BACKEND_URL = resolveEnv('REACT_APP_BACKEND_URL')
export const REACT_PUBLIC_API_KEY = resolveEnv('REACT_PUBLIC_API_KEY')

export const MOCK_API = false

export const MOCK_PORT_MAPPING = {
  auth: 8000,
  client: 8001,
}

export const TABLET_SCREEN_WIDTH = 1024
export const PHONE_SCREEN_WIDTH = 800
export const DATE_FORMAT = 'DD. MM. YYYY'
export const DATABASE_DATE_FORMAT = 'YYYY-MM-DD'

export const ADDRESS_NUMBER_KINDS = [
  {value: 'descriptive_number', label: 'č.p.', addressNumberName: 'Číslo popisné'},
  {value: 'evidence_number', label: 'ev.č.', addressNumberName: 'Evidenční číslo'},
]

export const GENDERS = [
  {value: 'male', label: 'muž'},
  {value: 'female', label: 'žena'},
]

export const MARITAL_STATUSES = [
  {value: 'single', male: 'svobodný', female: 'svobodná'},
  {value: 'married', male: 'ženatý', female: 'vdaná'},
  {value: 'divorced', male: 'rozvedený', female: 'rozvedená'},
  {value: 'widow', male: 'vdovec', female: 'vdova'},
  {
    value: 'registered_partner',
    male: 'registrované partnerství',
    female: 'registrované partnerství',
  },
]

export const BANK_NUMBERS = [
  {value: '0100', label: '0100 - Komerční banka, a.s.'},
  {value: '0300', label: '0300 - ČSOB, a.s'},
  {value: '0600', label: '0600 - MONETA Money Bank, a.s.'},
  {value: '0710', label: '0710 - Česká národní banka'},
  {value: '0800', label: '0800 - Česká spořitelna, a.s.'},
  {value: '2010', label: '2010 - Fio banka, a.s.'},
  {value: '2020', label: '2020 - MUFG Bank (Europe) N.V. Prague Branch'},
  {value: '2030', label: '2030 - AKCENTA, spořitelní a úvěrní družstvo'},
  {value: '2060', label: '2060 - Citfin, spořitelní družstvo'},
  {value: '2070', label: '2070 - Moravský Peněžní Ústav – spořitelní družstvo'},
  {value: '2100', label: '2100 - Hypoteční banka, a.s.'},
  {value: '2200', label: '2200 - Peněžní dům, spořitelní družstvo'},
  {value: '2220', label: '2220 - Artesa, spořitelní družstvo'},
  {value: '2240', label: '2240 - Poštová banka, a.s., pobočka Česká republika'},
  {value: '2250', label: '2250 - Banka CREDITAS a.s.'},
  {value: '2260', label: '2260 - ANO spořitelní družstvo'},
  {value: '2310', label: '2310 - ZUNO BANK AG, organizační složka'},
  {value: '2600', label: '2600 - Citibank Europe plc, organizační složka'},
  {value: '2700', label: '2700 - UniCredit Bank Czech Republic and Slovakia, a.s.'},
  {value: '3030', label: '3030 - Air Bank a. s.'},
  {value: '3050', label: '3050 - BNP Paribas Personal Finance SA, odštěpný závod'},
  {value: '3060', label: '3060 - PKO BP S.A., Czech Branch'},
  {value: '3500', label: '3500 - ING Bank N.V.'},
  {value: '4000', label: '4000 - Expobank CZ a.s.'},
  {value: '4300', label: '4300 - Českomoravská záruční a rozvojová banka, a.s.'},
  {value: '5500', label: '5500 - Raiffeisenbank, a.s.'},
  {value: '5800', label: '5800 - J&T Banka, a.s.'},
  {value: '6000', label: '6000 - PPF banka a.s.'},
  {value: '6100', label: '6100 - Equa bank a. s.'},
  {value: '6200', label: '6200 - COMMERZBANK Aktiengesellschaft, pobočka Praha'},
  {value: '6210', label: '6210 - mBank S.A., organizační složka'},
  {value: '6300', label: '6300 - BNP Paribas Fortis SA/NV, pobočka Česká republika'},
  {value: '6700', label: '6700 - Všeobecná úverová banka a.s., pobočka Praha'},
  {value: '6800', label: '6800 - Sberbank CZ, a.s.'},
  {value: '7910', label: '7910 - Deutsche Bank A.G. Filiale Prag'},
  {value: '7940', label: '7940 - Waldviertler Sparkasse Bank AG'},
  {value: '7950', label: '7950 - Raiffeisen stavební spořitelna a.s.'},
  {value: '7960', label: '7960 - Českomoravská stavební spořitelna a. s.'},
  {value: '7970', label: '7970 - Wüstenrot - stavební spořitelna a.s.'},
  {value: '7980', label: '7980 - Wüstenrot hypoteční banka, a.s.'},
  {value: '7990', label: '7990 - Modrá pyramida stavební spořitelna, a.s.'},
  {
    value: '8030',
    label: '8030 - Raiffeisenbank im Stiftland Waldsassen eG pobočka Cheb, odštěpný závod',
  },
  {value: '8040', label: '8040 - Oberbank AG pobočka Česká republika'},
  {value: '8060', label: '8060 - Stavební spořitelna České spořitelny, a.s.'},
  {value: '8090', label: '8090 - Česká exportní banka, a.s.'},
  {value: '8150', label: '8150 - HSBC Bank plc - pobočka Praha'},
  {
    value: '8200',
    label: '8200 - PRIVAT BANK AG der Raiffeisenlandesbank Oberösterreich v České republice',
  },
  {value: '8220', label: '8220 - Payment Execution s.r.o.'},
  {value: '8230', label: '8230 - EEPAYS s. r. o.'},
  {value: '8240', label: '8240 - Družstevní záložna Kredit'},
  {value: '8250', label: '8250 - Bank of China (Hungary) Close Ltd. Prague branch, odštěpný závod'},
]
