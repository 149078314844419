import httpClient from 'helper/httpClient'
import endpoints from 'helper/endpoints'
import {globalApiErrorHandler} from 'helper/functions'

export const getPickups = () => {
  return httpClient
    .get(endpoints.pickups, {limit: 1000})
    .then((res) => {
      return res.data?.objects
    })
    .catch(globalApiErrorHandler)
}
