import endpoints from 'helper/endpoints'
import {globalApiErrorHandler, rethrow} from 'helper/functions'
import httpClient from 'helper/httpClient'
import {
  GET_CONDUCTS_REQUEST,
  GET_CONDUCTS_SUCCESS,
  GET_CONDUCTS_FAIL,
  GET_CONDUCT_DETAIL_REQUEST,
  GET_CONDUCT_DETAIL_SUCCESS,
  GET_CONDUCT_DETAIL_FAIL,
} from 'redux/actionTypes'

export const getConducts = () =>
  function (dispatch) {
    dispatch({type: GET_CONDUCTS_REQUEST})
    return httpClient
      .get(endpoints.conducts)
      .then((response) => {
        dispatch({
          type: GET_CONDUCTS_SUCCESS,
          data: response.data,
        })
      })
      .catch(rethrow(() => dispatch({type: GET_CONDUCTS_FAIL})))
      .catch(globalApiErrorHandler)
  }

export const getConductDetail = (conductId) =>
  function (dispatch) {
    dispatch({type: GET_CONDUCT_DETAIL_REQUEST, data: {conductId}})
    return httpClient
      .get(`${endpoints.conducts}/${conductId}`)
      .then((response) => {
        dispatch({
          type: GET_CONDUCT_DETAIL_SUCCESS,
          data: response.data,
        })
      })
      .catch(rethrow(() => dispatch({type: GET_CONDUCT_DETAIL_FAIL})))
      .catch(globalApiErrorHandler)
  }
