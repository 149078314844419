import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import styles from './myAccount.module.scss'
import formStyles from 'component/form/formFields.module.scss'
import {getClientProfile, getUserProfileWithKind} from 'redux/actions/user'
import cx from 'classnames'
import Dialog from 'component/Dialog/Dialog'
import MyAccountDetailPerson from 'component/myAccount/MyAccountDetailPerson'
import MyAccountDetailLegal from 'component/myAccount/MyAccountDetailLegal'
import PasswordEditForm from 'component/myAccount/PasswordEditForm'
import MyAccountEditPersonForm from 'component/myAccount/MyAccountEditPersonForm'
import MyAccountEditLegalForm from 'component/myAccount/MyAccountEditLegalForm'

const MyAccountDetail = (props) => {
  const {profileWithKind, getClientProfile, getUserProfileWithKind} = props

  const [editDialogVisible, setEditDialogVisible] = useState(false)
  const [editPasswordDialogVisible, setEditPasswordDialogVisible] = useState(false)
  const [profileKind, setProfileKind] = useState(undefined)

  useEffect(() => {
    getClientProfile().then((res) => {
      if (res?.kind) {
        setProfileKind(res.kind)
        getUserProfileWithKind(res.kind)
      }
    })
  }, [getClientProfile, getUserProfileWithKind])

  const openEditDialog = () => {
    setEditDialogVisible(true)
  }

  const closeEditDialog = () => {
    setEditDialogVisible(false)
  }

  const openPasswordDialog = () => {
    setEditPasswordDialogVisible(true)
  }

  const closePasswordDialog = () => {
    setEditPasswordDialogVisible(false)
  }

  return (
    <div className={styles.accountDetailWrapper}>
      {editDialogVisible &&
        (profileKind === 'person' ? (
          <Dialog
            title="Údaje uživatele"
            handleClose={closeEditDialog}
            content={
              <MyAccountEditPersonForm
                kind={profileKind}
                profile={profileWithKind}
                closeDialog={closeEditDialog}
              />
            }
          />
        ) : (
          <Dialog
            title="Údaje subjektu"
            handleClose={closeEditDialog}
            content={
              <MyAccountEditLegalForm
                kind={profileKind}
                profile={profileWithKind}
                closeDialog={closeEditDialog}
              />
            }
          />
        ))}

      {editPasswordDialogVisible && (
        <Dialog
          title="Změna hesla"
          handleClose={closePasswordDialog}
          content={<PasswordEditForm closeDialog={closePasswordDialog} />}
        />
      )}

      {profileKind === 'person' ? (
        <MyAccountDetailPerson profile={profileWithKind} />
      ) : (
        <MyAccountDetailLegal kind={profileKind} profile={profileWithKind} />
      )}

      <div className={cx(formStyles.buttons, styles.editButtons)}>
        <button className={formStyles.button} onClick={openEditDialog}>
          UPRAVIT ÚDAJE
        </button>
        <button className={formStyles.button} onClick={openPasswordDialog}>
          ZMĚNIT HESLO
        </button>
      </div>
    </div>
  )
}

MyAccountDetail.propTypes = {
  profile: PropTypes.object,
  getClientProfile: PropTypes.func,
}

const mapStateToProps = (state) => ({
  profile: state.user.clientProfile,
  profileWithKind: state.user.clientProfileKind,
})

const mapDispatchToProps = {
  getClientProfile,
  getUserProfileWithKind,
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountDetail)
