import React from 'react'
import styles from './yourPropertyManager.module.scss'
import flaticon from 'asset/flaticon/flaticon.module.scss'
import classNames from 'classnames/bind'
import {TABLET_SCREEN_WIDTH} from 'helper/constants'

const fi = classNames.bind(flaticon)

const YourPropertyManager = (props) => {
  const {manager} = props

  const mobileView = window.innerWidth < TABLET_SCREEN_WIDTH

  const renderEmail = () => {
    if (mobileView) {
      const emailSplit = manager?.email?.split('@')
      return (
        <span>
          {emailSplit?.[0]}
          <br />@{emailSplit?.[1]}
        </span>
      )
    } else {
      return manager?.email
    }
  }

  return (
    <div className={styles.YourPropertyManager}>
      <div className={styles.title}>Váš správce</div>
      <div className={styles.detail}>
        <div className={styles.img}>
          <img src={manager?.photo_url} alt="" />
        </div>
        <div className={styles.information}>
          <div className={styles.name}>{manager?.name}</div>
          <div className={styles.phone}>
            <span className={classNames(fi('flaticon-call'))}>
              <a href={`tel:${manager?.phone}`}>{manager?.phone}</a>
            </span>
          </div>
          <div className={styles.sendMessageButton}>
            <span className={classNames(fi('flaticon-email'))} />
            <a href={`mailto:${manager?.email}`}>{renderEmail()}</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default YourPropertyManager
