import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom'
import {createUserImpersonation, userLoginRefresh} from 'redux/actions/user'

// this is applying styles for the whole document
import styles from './app.module.scss'
import LoginPage from 'page/LoginPage'
import HomePage from 'page/HomePage'
import DetailPage from 'page/DetailPage'
import Layout from './Layout'
import PrivateRoute from 'component/PrivateRoute'
import MyAccount from 'component/myAccount/MyAccount'
import StatisticsPage from 'page/StatisticsPage'
import RecommendPage from 'page/RecommendPage'
import PostPage from 'page/PostsPage'

const App = (props) => {
  const {userLoginRefresh, createUserImpersonation} = props
  useEffect(() => userLoginRefresh(), [userLoginRefresh])

  useEffect(() => {
    const impersonationFromStorage = localStorage.getItem('impersonation')
    if (impersonationFromStorage) {
      try {
        createUserImpersonation(JSON.parse(impersonationFromStorage).id)
      } catch {}
    }
  }, [createUserImpersonation])

  return (
    <>
      <ToastContainer />
      <Router>
        <Switch>
          <Route path="/login">
            <Layout loginLayout={true}>
              <LoginPage />
            </Layout>
          </Route>
          <PrivateRoute exact path="/" component={<HomePage />} />
          <PrivateRoute exact path="/muj-ucet" component={<MyAccount />} />
          <PrivateRoute exact path="/doporucit" component={<RecommendPage />} />
          <PrivateRoute path="/nemovitosti/:conductId" component={<DetailPage />} />
          <PrivateRoute path="/statistiky" component={<StatisticsPage />} />
          <PrivateRoute path="/zajimavosti" component={<PostPage />} />
          <Route path="/404">
            <Layout>
              <div className={styles.notFound}>Stránka nenalezena.</div>
            </Layout>
          </Route>
          <Redirect to="/404" />
        </Switch>
      </Router>
    </>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
  userLoginRefresh,
  createUserImpersonation,
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
