import React, {useEffect} from 'react'
import styles from './myProperty.module.scss'
import YourPropertyManager from 'component/yourPropertyManager/YourPropertyManager'
import {getConducts} from 'redux/actions/conducts'
import {connect} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {getUserProfileManager} from 'redux/actions/user'

const MyProperty = (props) => {
  const {conducts, getConducts, getUserProfileManager, manager} = props
  let history = useHistory()

  useEffect(() => {
    getConducts()
    getUserProfileManager()
  }, [getConducts, getUserProfileManager])

  const redirectTo = (id) => (e) => {
    history.push(`/nemovitosti/${id}/platby`)
  }

  return (
    <div className={styles.MyProperty}>
      <div className={styles.propertyList}>
        <div className={styles.title}>Moje nemovitosti</div>
        {conducts.map((conduct, index) => (
          <div onClick={redirectTo(conduct?.id)} className={styles.property} key={index}>
            {conduct?.unit?.title_with_address}
          </div>
        ))}
      </div>
      <YourPropertyManager manager={manager && manager} />
    </div>
  )
}

const mapStateToProps = (state) => ({
  conducts: state.conducts.data,
  conductsReceived: state.conducts.dataReceived,
  manager: state.user.manager,
})

const mapDispatchToProps = {
  getConducts,
  getUserProfileManager,
}

export default connect(mapStateToProps, mapDispatchToProps)(MyProperty)
