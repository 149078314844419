import {
  GET_CONDUCT_PAYMENTS_REQUEST,
  GET_CONDUCT_PAYMENTS_SUCCESS,
  GET_CONDUCT_PAYMENTS_FAIL,
} from 'redux/actionTypes'
const initialState = {dataReceived: false, getInProgress: false, data: [], meta: {}}

const getConductPaymentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONDUCT_PAYMENTS_REQUEST: {
      return {...state, getInProgress: true}
    }
    case GET_CONDUCT_PAYMENTS_SUCCESS: {
      return {
        ...state,
        dataReceived: true,
        getInProgress: false,
        data: action.data.objects,
        meta: action.data.meta,
      }
    }
    case GET_CONDUCT_PAYMENTS_FAIL: {
      return {...state, getInProgress: false}
    }
    default: {
      return state
    }
  }
}

export default getConductPaymentsReducer
