import React from 'react'
import formStyles from 'component/form/formFields.module.scss'
import cx from 'classnames'
import PropTypes from 'prop-types'

const CheckboxInput = (props) => {
  const {label, meta, input, ...rest} = props

  const errorMessage = meta && meta.touched && (meta.error || meta.submitError)

  return (
    <div className={formStyles.checkBoxWrapper}>
      <input
        {...input}
        {...rest}
        className={cx(formStyles.checkBoxField, errorMessage ? formStyles.errorField : '')}
        type="checkbox"
      />
      <label className={formStyles.checkBoxLabel}>{label && label}</label>
      {errorMessage && <span className={formStyles.errorMessage}>{errorMessage}</span>}
    </div>
  )
}

CheckboxInput.propTypes = {
  label: PropTypes.string,
  meta: PropTypes.object,
  input: PropTypes.object,
}

export default CheckboxInput
