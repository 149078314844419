import {
  GET_CONDUCTS_REQUEST,
  GET_CONDUCTS_SUCCESS,
  GET_CONDUCTS_FAIL,
  GET_CONDUCT_DETAIL_REQUEST,
  GET_CONDUCT_DETAIL_SUCCESS,
  GET_CONDUCT_DETAIL_FAIL,
} from 'redux/actionTypes'

const initialState = {
  dataReceived: false,
  getInProgress: false,
  getDetailReqState: {
    id: null,
    inProgress: false,
    succeeded: null,
  },
  data: [],
  tenants: [],
  detail: {},
  meta: {},
}

const conductsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONDUCTS_REQUEST: {
      return {...state, getInProgress: true}
    }
    case GET_CONDUCTS_SUCCESS: {
      return {
        ...state,
        dataReceived: true,
        getInProgress: false,
        data: action.data.objects,
        meta: action.data.meta,
      }
    }
    case GET_CONDUCTS_FAIL: {
      return {...state, getInProgress: false}
    }

    case GET_CONDUCT_DETAIL_REQUEST: {
      return {
        ...state,
        getDetailInProgress: true,
        getDetailReqState: {id: action.data.conductId, inProgress: true, succeeded: null},
      }
    }
    case GET_CONDUCT_DETAIL_SUCCESS: {
      return {
        ...state,
        detail: action.data,
        tenants: action.data.active_lease?.tenants,
        data: action.data ? [action.data] : [],
        getDetailReqState: {...state.getDetailReqState, inProgress: false, succeeded: true},
      }
    }
    case GET_CONDUCT_DETAIL_FAIL: {
      return {
        ...state,
        getDetailReqState: {...state.getDetailReqState, inProgress: false, succeeded: false},
      }
    }
    default: {
      return state
    }
  }
}

export default conductsReducer
