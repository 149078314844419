import endpoints from 'helper/endpoints'
import {globalApiErrorHandler, rethrow} from 'helper/functions'
import httpClient from 'helper/httpClient'
import {
  GET_LEASES_REQUEST,
  GET_LEASES_SUCCESS,
  GET_LEASES_FAIL,
  GET_LEASE_REQUEST,
  GET_LEASE_SUCCESS,
  GET_LEASE_FAIL,
} from 'redux/actionTypes'

export const getLeases = (conductId) =>
  function (dispatch) {
    dispatch({type: GET_LEASES_REQUEST})
    return httpClient
      .get(`${endpoints.conducts}/${conductId}/leases`)
      .then((response) => {
        dispatch({
          type: GET_LEASES_SUCCESS,
          data: response.data,
        })
      })
      .catch(rethrow(() => dispatch({type: GET_LEASES_FAIL})))
      .catch(globalApiErrorHandler)
  }

export const getLeaseDetail = (conductId, leaseId) =>
  function (dispatch) {
    dispatch({type: GET_LEASE_REQUEST, data: {conductId}})
    return httpClient
      .get(`${endpoints.conducts}/${conductId}/leases/${leaseId}`)
      .then((response) => {
        dispatch({
          type: GET_LEASE_SUCCESS,
          data: response.data,
        })
      })
      .catch(rethrow(() => dispatch({type: GET_LEASE_FAIL})))
      .catch(globalApiErrorHandler)
  }
