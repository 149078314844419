import React, {useEffect, useState} from 'react'
import styles from 'component/inspectionsTable/inspectionsTable.module.scss'
import Table from 'component/Table'
import endpoints from 'helper/endpoints'
import {connect} from 'react-redux'
import {getConductInspections} from 'redux/actions/conductInspections'
import {DATE_FORMAT} from 'helper/constants'
import moment from 'moment'
import Carousel from 'component/inspectionsTable/Carousel'
import DownloadPdf from 'component/DownloadPdf'
import PropTypes from 'prop-types'
import Loader from 'component/Loader'

const InspectionsTable = (props) => {
  const {
    conductId,
    leaseId,
    getConductInspections,
    inspections,
    inspectionsMeta,
    loading,
    dataReceived,
  } = props

  const [carousel, setCarousel] = useState({visible: false, currentIndex: 0, images: []})
  const [page, setPage] = useState(0)

  useEffect(() => {
    if (conductId && leaseId) {
      getConductInspections(conductId, leaseId, page)
    }
  }, [getConductInspections, conductId, leaseId, page])

  const openImageCarousel = (imgIndex, images) => (e) => {
    setCarousel({visible: true, currentIndex: imgIndex, images: images.map((img) => img.file_url)})
  }

  const closeImageCarousel = () => (e) => {
    setCarousel({visible: false, currentIndex: 0, images: []})
  }

  const fields = [
    {key: 'date', title: 'Datum', render: (value) => value && moment(value).format(DATE_FORMAT)},
    {
      key: 'minutes',
      title: 'Zápis',
      render: (value, field, row) =>
        row.is_downloadable ? (
          <DownloadPdf
            downloadUrl={`${endpoints.conducts}/${conductId}/leases/${leaseId}/supervisions/${row.id}/download`}
          />
        ) : (
          ''
        ),
    },
    {
      key: 'attachments',
      title: 'Fotografie',
      style: styles.images,
      render: (value) => {
        let data = value
        if (data?.length > 7) {
          data = data.slice(0, 7)
          data.push('showAll')
        }
        return data?.map((image, index) =>
          image !== 'showAll' ? (
            <img
              onClick={openImageCarousel(index, value)}
              key={index}
              src={image.file_url}
              alt=""
            />
          ) : (
            <div
              onClick={openImageCarousel(index, value)}
              className={styles.showAllText}
              key="show all"
            >
              Zobrazit vše
            </div>
          )
        )
      },
    },
  ]

  const handleNextPage = () => {
    if (inspectionsMeta.limit * (page + 1) < inspectionsMeta.total_count) {
      setPage((prevState) => prevState + 1)
    }
  }

  const handlePageClick = (page) => {
    if (page > 0 || inspectionsMeta.limit * (page + 1) < inspectionsMeta.total_count) {
      setPage(page)
    }
  }

  const handlePreviousPage = () => {
    if (page > 0) {
      setPage((prevState) => prevState - 1)
    }
  }

  return (
    <>
      {carousel.visible && <Carousel carousel={carousel} closeImageCarousel={closeImageCarousel} />}
      <div className={styles.InspectionsTable}>
        {loading && !dataReceived ? (
          <Loader />
        ) : (
          <Table
            fields={fields}
            data={inspections}
            pagination={true}
            page={page}
            maxPage={Math.ceil(inspectionsMeta.total_count / inspectionsMeta.limit)}
            nextPage={handleNextPage}
            prevPage={handlePreviousPage}
            goToPage={handlePageClick}
          />
        )}
      </div>
    </>
  )
}

InspectionsTable.propTypes = {
  conductId: PropTypes.number,
  leaseId: PropTypes.number,
  getConductInspections: PropTypes.func,
  inspections: PropTypes.array,
  inspectionsMeta: PropTypes.object,
  loading: PropTypes.bool,
  dataReceived: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  inspections: state.conductInspections.data,
  inspectionsMeta: state.conductInspections.meta,
  loading: state.conductInspections.getInProgress,
  dataReceived: state.conductInspections.dataReceived,
})

const mapDispatchToProps = {
  getConductInspections,
}

export default connect(mapStateToProps, mapDispatchToProps)(InspectionsTable)
