import React from 'react'
import {Form, Field} from 'react-final-form'
import formStyles from 'component/form/formFields.module.scss'
import TextInput from 'component/form/TextInput'
import FormRow from 'component/form/FormRow'
import {connect} from 'react-redux'
import {sendDoporucitZnamemu} from 'redux/actions/doporucitZnamemu'
import {fireErrorToast, fireSuccessToast, mapErrorResponseToForm} from 'helper/functions'
import {required, validateEmail, validatePhoneNumber} from 'helper/validations'

const MyAccountEditPersonForm = (props) => {
  const {sendDoporucitZnamemu, onSend} = props

  const handleSend = (values) => {
    return sendDoporucitZnamemu(values)
      .then(() => {
        fireSuccessToast('Doporučení odesláno')
        onSend()
      })
      .catch((err) => {
        fireErrorToast('Odeslání doporučení selhalo')
        return mapErrorResponseToForm(err)
      })
  }

  return (
    <Form
      onSubmit={handleSend}
      render={({handleSubmit, submitting}) => {
        return (
          <form onSubmit={handleSubmit} className={formStyles.form}>
            <FormRow>
              <Field
                name="name_firstname"
                label="Jméno"
                component={TextInput}
                validate={required}
              />
              <Field
                name="name_lastname"
                label="Příjmení"
                component={TextInput}
                validate={required}
              />
            </FormRow>

            <FormRow>
              <Field
                name="phone"
                label="Telefon"
                component={TextInput}
                validate={validatePhoneNumber}
              />
              <Field name="email" label="E-mail" component={TextInput} validate={validateEmail} />
            </FormRow>

            <FormRow>
              <Field
                name="message"
                label="Zpráva pro nás"
                component={TextInput}
                validate={required}
                styles={formStyles.fieldRow}
              />
            </FormRow>
            <p>
              Prosíme, před odesláním kontaktu informujte doporučeného, že jej bude kontaktovat
              zástupce naší společnosti. V dnešní době neustálých obchodních nabídek bychom neradi
              někoho zbytečně obtěžovali. Děkujeme.
            </p>

            <div className={formStyles.buttons}>
              <button className={formStyles.button} type="submit" disabled={submitting}>
                ODESLAT
              </button>
            </div>
          </form>
        )
      }}
    />
  )
}

MyAccountEditPersonForm.propTypes = {}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {sendDoporucitZnamemu}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountEditPersonForm)
