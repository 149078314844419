import {combineReducers} from 'redux'
import user from './user'
import conducts from './conducts'
import conductPayments from './conductPayments'
import conductContracts from './conductContracts'
import conductCommunications from './conductCommunications'
import conductAccountings from './conductAccountings'
import conductInspections from './conductInspections'
import impersonation from './impersonation'
import conductLease from './conductLease'
import statistics from './statistics'
import posts from './postsReducer'

export default combineReducers({
  user,
  conducts,
  conductPayments,
  conductContracts,
  conductCommunications,
  conductAccountings,
  conductInspections,
  conductLease,
  impersonation,
  statistics,
  posts,
})
