import {getServiceUrl} from 'helper/functions'

const endpoints = {
  login: `${getServiceUrl('auth')}/login`,
  logout: `${getServiceUrl('auth')}/logout`,
  password: `${getServiceUrl('auth')}/profile/password`,
  authProfile: `${getServiceUrl('auth')}/profile`,

  clientProfile: `${getServiceUrl('client')}/profile`,
  manager: `${getServiceUrl('client')}/profile/manager`,

  conducts: `${getServiceUrl('client')}/conducts`,
  impersonation: `${getServiceUrl('client')}/impersonation`,
  statistics: `${getServiceUrl('common')}/statistics`,
  recommendations: `${getServiceUrl('client')}/recommendations`,
  interests: `${getServiceUrl('common')}/interests`,
  pickups: `${getServiceUrl('client')}/pickups`,
}

export default endpoints
