import React, {useEffect} from 'react'
import styles from 'component/Dialog/dialog.module.scss'
import flaticon from 'asset/flaticon/flaticon.module.scss'
import classNames from 'classnames/bind'
import PropTypes from 'prop-types'

const fi = classNames.bind(flaticon)

const Dialog = (props) => {
  const {title, content, children, handleClose} = props

  useEffect(() => {
    const dialogRef = document.getElementById('dialog').offsetTop
    window.scroll({top: dialogRef - 50, left: 0, behavior: 'smooth'})
  }, [])

  return (
    <div className={styles.dialogOverlay}>
      <div className={styles.dialogContent} id="dialog">
        <div className={styles.dialogTitle}>{title}</div>
        <div className={styles.closeButton} onClick={handleClose}>
          <span className={fi('flaticon-close')} />
        </div>
        {content || children}
      </div>
    </div>
  )
}

Dialog.propTypes = {
  title: PropTypes.string,
  content: PropTypes.node,
  handleClose: PropTypes.func,
}

export default Dialog
