import React from 'react'
import styles from 'component/myAccount/myAccount.module.scss'
import PropTypes from 'prop-types'
import {TABLET_SCREEN_WIDTH} from 'helper/constants'

const MyAccountDetailLegal = (props) => {
  const {profile} = props

  const mobileView = window.innerWidth < TABLET_SCREEN_WIDTH

  const renderEmail = (email) => {
    if (mobileView) {
      const emailSplit = email?.split('@')
      return (
        <span>
          {emailSplit?.[0]}
          <br />@{emailSplit?.[1]}
        </span>
      )
    } else {
      return email
    }
  }

  return (
    <div className={styles.accountDetail}>
      <table>
        <tbody>
          <tr>
            <td className={styles.title}>Název subjektu</td>
            <td>{profile?.legal_name}</td>
          </tr>
          <tr>
            <td className={styles.title}>E-mail</td>
            <td>{profile?.email && renderEmail(profile.email)}</td>
          </tr>
          <tr>
            <td className={styles.title}>Telefon (mobilní)</td>
            <td>{profile?.phone_mobile}</td>
          </tr>
          <tr>
            <td className={styles.title}>Adresa sídla</td>
            <td>{profile?.legal_address}</td>
          </tr>
          {!profile?.legal_contact_address_similar && (
            <tr>
              <td className={styles.title}>Doručovací adresa</td>
              <td>{profile?.legal_contact_address}</td>
            </tr>
          )}
        </tbody>
      </table>
      <table>
        <tbody>
          <tr>
            <td className={styles.title}>IČO</td>
            <td>{profile?.legal_org_id}</td>
          </tr>
          <tr>
            <td className={styles.title}>DIČ</td>
            <td>{profile?.legal_tax_id}</td>
          </tr>
          <tr>
            <td className={styles.title}>Bankovní účet</td>
            <td>{profile?.account_number}</td>
          </tr>
          <tr>
            <td className={styles.title}>Kód banky</td>
            <td>{profile?.bank_code}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

MyAccountDetailLegal.propTypes = {
  profile: PropTypes.object,
}

export default MyAccountDetailLegal
