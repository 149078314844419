import endpoints from 'helper/endpoints'
import {fireSuccessToast, globalApiErrorHandler, rethrow} from 'helper/functions'
import httpClient from 'helper/httpClient'
import {
  GET_CONDUCT_ACCOUNTINGS_REQUEST,
  GET_CONDUCT_ACCOUNTINGS_SUCCESS,
  GET_CONDUCT_ACCOUNTINGS_FAIL,
  PUT_CONDUCT_ACCOUNTINGS_REQUEST,
  PUT_CONDUCT_ACCOUNTINGS_SUCCESS,
  PUT_CONDUCT_ACCOUNTINGS_FAIL,
} from 'redux/actionTypes'

export const getConductAccountings = (conductId, leaseId, page = 0) =>
  function (dispatch) {
    dispatch({type: GET_CONDUCT_ACCOUNTINGS_REQUEST})

    const params = {
      limit: 20,
      offset: page * 20,
    }

    return httpClient
      .get(`${endpoints.conducts}/${conductId}/leases/${leaseId}/settlements`, params)
      .then((response) => {
        dispatch({
          type: GET_CONDUCT_ACCOUNTINGS_SUCCESS,
          data: response.data,
        })
      })
      .catch(rethrow(() => dispatch({type: GET_CONDUCT_ACCOUNTINGS_FAIL})))
      .catch(globalApiErrorHandler)
  }

export const putConductAccountingsApprove = (conductId, leaseId, accountingId) =>
  function (dispatch) {
    dispatch({type: PUT_CONDUCT_ACCOUNTINGS_REQUEST})
    return httpClient
      .put(
        `${endpoints.conducts}/${conductId}/leases/${leaseId}/settlements/${accountingId}/approve`
      )
      .then((response) => {
        dispatch({
          type: PUT_CONDUCT_ACCOUNTINGS_SUCCESS,
          data: response.data,
        })
        dispatch(getConductAccountings(conductId, leaseId))
        fireSuccessToast('Vyúčtování schváleno')
      })
      .catch((err) => {
        dispatch({type: PUT_CONDUCT_ACCOUNTINGS_FAIL})
        globalApiErrorHandler(err)
      })
  }
