import React, {useEffect} from 'react'
import styles from './myAccount.module.scss'
import MyAccountDetail from 'component/myAccount/MyAccountDetail'
import MyProperty from 'component/myAccount/MyProperty'

const MyAccount = () => {
  useEffect(() => {
    window.scroll({top: 0, left: 0, behavior: 'smooth'})
  }, [])

  return (
    <div className={styles.MyAccount}>
      <div className={styles.topPart}>
        <MyAccountDetail />
      </div>
      <MyProperty />
    </div>
  )
}

export default MyAccount
