export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'

export const USER_LOGIN_REFRESH_REQUEST = 'USER_LOGIN_REFRESH_REQUEST'
export const USER_LOGIN_REFRESH_SUCCESS = 'USER_LOGIN_REFRESH_SUCCESS'
export const USER_LOGIN_REFRESH_FAIL = 'USER_LOGIN_REFRESH_FAIL'

export const USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST'
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS'
export const USER_LOGOUT_FAIL = 'USER_LOGOUT_FAIL'

export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST'
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS'
export const GET_PROFILE_FAIL = 'GET_PROFILE_FAIL'

export const GET_PROFILE_KIND_REQUEST = 'GET_PROFILE_KIND_REQUEST'
export const GET_PROFILE_KIND_SUCCESS = 'GET_PROFILE_KIND_SUCCESS'
export const GET_PROFILE_KIND_FAIL = 'GET_PROFILE_KIND_FAIL'

export const GET_USER_PROFILE_MANAGER_REQUEST = 'GET_USER_PROFILE_MANAGER_REQUEST'
export const GET_USER_PROFILE_MANAGER_SUCCESS = 'GET_USER_PROFILE_MANAGER_SUCCESS'
export const GET_USER_PROFILE_MANAGER_FAIL = 'GET_USER_PROFILE_MANAGER_FAIL'

export const EDIT_PROFILE_REQUEST = 'EDIT_PROFILE_REQUEST'
export const EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS'
export const EDIT_PROFILE_FAIL = 'EDIT_PROFILE_FAIL'

export const EDIT_PASSWORD_REQUEST = 'EDIT_PASSWORD_REQUEST'
export const EDIT_PASSWORD_SUCCESS = 'EDIT_PASSWORD_SUCCESS'
export const EDIT_PASSWORD_FAIL = 'EDIT_PASSWORD_FAIL'

export const USER_CREATE_IMPERSONATION_REQUEST = 'USER_CREATE_IMPERSONATION_REQUEST'
export const USER_CREATE_IMPERSONATION_SUCCESS = 'USER_CREATE_IMPERSONATION_SUCCESS'
export const USER_CREATE_IMPERSONATION_FAIL = 'USER_CREATE_IMPERSONATION_FAIL'

export const USER_DESTROY_IMPERSONATION_REQUEST = 'USER_DESTROY_IMPERSONATION_REQUEST'
export const USER_DESTROY_IMPERSONATION_SUCCESS = 'USER_DESTROY_IMPERSONATION_SUCCESS'
export const USER_DESTROY_IMPERSONATION_FAIL = 'USER_DESTROY_IMPERSONATION_FAIL'

export const GET_CONDUCTS_REQUEST = 'GET_CONDUCTS_REQUEST'
export const GET_CONDUCTS_SUCCESS = 'GET_CONDUCTS_SUCCESS'
export const GET_CONDUCTS_FAIL = 'GET_CONDUCTS_FAIL'

export const GET_CONDUCT_DETAIL_REQUEST = 'GET_CONDUCT_DETAIL_REQUEST'
export const GET_CONDUCT_DETAIL_SUCCESS = 'GET_CONDUCT_DETAIL_SUCCESS'
export const GET_CONDUCT_DETAIL_FAIL = 'GET_CONDUCT_DETAIL_FAIL'

export const GET_CONDUCT_PAYMENTS_REQUEST = 'GET_CONDUCT_PAYMENTS_REQUEST'
export const GET_CONDUCT_PAYMENTS_SUCCESS = 'GET_CONDUCT_PAYMENTS_SUCCESS'
export const GET_CONDUCT_PAYMENTS_FAIL = 'GET_CONDUCT_PAYMENTS_FAIL'

export const GET_CONDUCT_CONTRACTS_REQUEST = 'GET_CONDUCT_CONTRACTS_REQUEST'
export const GET_CONDUCT_CONTRACTS_SUCCESS = 'GET_CONDUCT_CONTRACTS_SUCCESS'
export const GET_CONDUCT_CONTRACTS_FAIL = 'GET_CONDUCT_CONTRACTS_FAIL'

export const GET_CONDUCT_COMMUNICATIONS_REQUEST = 'GET_CONDUCT_COMMUNICATIONS_REQUEST'
export const GET_CONDUCT_COMMUNICATIONS_SUCCESS = 'GET_CONDUCT_COMMUNICATIONS_SUCCESS'
export const GET_CONDUCT_COMMUNICATIONS_FAIL = 'GET_CONDUCT_COMMUNICATIONS_FAIL'

export const GET_CONDUCT_ACCOUNTINGS_REQUEST = 'GET_CONDUCT_ACCOUNTINGS_REQUEST'
export const GET_CONDUCT_ACCOUNTINGS_SUCCESS = 'GET_CONDUCT_ACCOUNTINGS_SUCCESS'
export const GET_CONDUCT_ACCOUNTINGS_FAIL = 'GET_CONDUCT_ACCOUNTINGS_FAIL'

export const PUT_CONDUCT_ACCOUNTINGS_REQUEST = 'PUT_CONDUCT_ACCOUNTINGS_REQUEST'
export const PUT_CONDUCT_ACCOUNTINGS_SUCCESS = 'PUT_CONDUCT_ACCOUNTINGS_SUCCESS'
export const PUT_CONDUCT_ACCOUNTINGS_FAIL = 'PUT_CONDUCT_ACCOUNTINGS_FAIL'

export const GET_DOWNLOAD_KEY_REQUEST = 'GET_DOWNLOAD_KEY_REQUEST'
export const GET_DOWNLOAD_KEY_SUCCESS = 'GET_DOWNLOAD_KEY_SUCCESS'
export const GET_DOWNLOAD_KEY_FAIL = 'GET_DOWNLOAD_KEY_FAIL'

export const GET_CONDUCT_INSPECTIONS_REQUEST = 'GET_CONDUCT_INSPECTIONS_REQUEST'
export const GET_CONDUCT_INSPECTIONS_SUCCESS = 'GET_CONDUCT_INSPECTIONS_SUCCESS'
export const GET_CONDUCT_INSPECTIONS_FAIL = 'GET_CONDUCT_INSPECTIONS_FAIL'

export const GET_IMPERSONATION_REQUEST = 'GET_IMPERSONATION_REQUEST'
export const GET_IMPERSONATION_SUCCESS = 'GET_IMPERSONATION_SUCCESS'
export const GET_IMPERSONATION_FAIL = 'GET_IMPERSONATION_FAIL'

export const GET_STATISTICS_REQUEST = 'GET_STATISTICS_REQUEST'
export const GET_STATISTICS_SUCCESS = 'GET_STATISTICS_SUCCESS'
export const GET_STATISTICS_FAIL = 'GET_STATISTICS_FAIL'

export const SEND_DOPORUCIT_REQUEST = 'SEND_DOPORUCIT_REQUEST'
export const SEND_DOPORUCIT_SUCCESS = 'SEND_DOPORUCIT_SUCCESS'
export const SEND_DOPORUCIT_FAIL = 'SEND_DOPORUCIT_FAIL'

export const GET_POST_REQUEST = 'GET_POST_REQUEST'
export const GET_POST_SUCCESS = 'GET_POST_SUCCESS'
export const GET_POST_FAIL = 'GET_POST_FAIL'

export const GET_LEASES_REQUEST = 'GET_LEASES_REQUEST'
export const GET_LEASES_SUCCESS = 'GET_LEASES_SUCCESS'
export const GET_LEASES_FAIL = 'GET_LEASES_FAIL'
export const GET_LEASE_REQUEST = 'GET_LEASE_REQUEST'
export const GET_LEASE_SUCCESS = 'GET_LEASE_SUCCESS'
export const GET_LEASE_FAIL = 'GET_LEASE_FAIL'

