import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import styles from './contractsTable.module.scss'
import {getConductContracts} from 'redux/actions/conductContracts'
import {DATE_FORMAT} from 'helper/constants'
import Table from 'component/Table'
import DownloadPdf from 'component/DownloadPdf'
import endpoints from 'helper/endpoints'
import Loader from 'component/Loader'

const ContractsTable = (props) => {
  const {
    conductId,
    leaseId,
    contracts,
    contractsMeta,
    getConductContracts,
    loading,
    dataReceived,
  } = props

  const [page, setPage] = useState(0)

  useEffect(() => {
    if (conductId && leaseId) {
      getConductContracts(conductId, leaseId, page)
    }
  }, [getConductContracts, conductId, leaseId, page])

  const fields = [
    {key: 'title', title: 'Název dokumentu', style: styles.firstColumn},
    {key: 'date', title: 'Datum podpisu'},
    {key: 'pdf', title: 'PDF'},
    {key: 'note', title: 'Poznámka'},
  ]

  const data =
    contracts &&
    contracts.map((item) => {
      return {
        ...item,
        date: item.date && moment(item.date).format(DATE_FORMAT),
        pdf: item.is_downloadable ? (
          <DownloadPdf
            downloadUrl={`${endpoints.conducts}/${conductId}/leases/${leaseId}/contracts/${item.id}/download`}
          />
        ) : (
          ''
        ),
        _key: item.id,
      }
    })

  const handleNextPage = () => {
    if (contractsMeta.limit * (page + 1) < contractsMeta.total_count) {
      setPage((prevState) => prevState + 1)
    }
  }

  const handlePageClick = (page) => {
    if (page > 0 || contractsMeta.limit * (page + 1) < contractsMeta.total_count) {
      setPage(page)
    }
  }

  const handlePreviousPage = () => {
    if (page > 0) {
      setPage((prevState) => prevState - 1)
    }
  }

  return (
    <div className={styles.ContractsTable}>
      {loading && !dataReceived ? (
        <Loader />
      ) : (
        <Table
          fields={fields}
          data={data}
          pagination={true}
          page={page}
          maxPage={Math.ceil(contractsMeta.total_count / contractsMeta.limit)}
          nextPage={handleNextPage}
          prevPage={handlePreviousPage}
          goToPage={handlePageClick}
        />
      )}
    </div>
  )
}

ContractsTable.propTypes = {
  conductId: PropTypes.number,
  leaseId: PropTypes.number,
  contracts: PropTypes.array,
  contractsMeta: PropTypes.object,
  getConductContracts: PropTypes.func,
  loading: PropTypes.bool,
  dataReceived: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  contracts: state.conductContracts.data,
  contractsMeta: state.conductContracts.meta,
  loading: state.conductContracts.getInProgress,
  dataReceived: state.conductContracts.dataReceived,
})

const mapDispatchToProps = {
  getConductContracts,
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractsTable)
