import React from 'react'
import formStyles from 'component/form/formFields.module.scss'
import Select from 'react-dropdown-select'
import PropTypes from 'prop-types'

// library is not able to take styles from module, therefore there is special scss file
import 'component/form/react-dropdown-select.scss'
import cx from 'classnames'

const SelectInput = (props) => {
  const {onChange, options, labelField = 'label', valueField = 'value', label, input} = props

  const classNames = cx(
    formStyles.field,
    !!input?.value && input?.value?.length !== 0 ? formStyles.notEmpty : ''
  )
  const onChangeFunction = onChange ? onChange : input.onChange
  const selectedValue = options.find((option) => option.value === input.value)

  return (
    <div className={classNames}>
      <label className={formStyles.label}>{label && label}</label>
      <div style={{width: '100%'}}>
        <Select
          values={selectedValue && [selectedValue]}
          placeholder=""
          options={options}
          labelField={labelField}
          valueField={valueField}
          onChange={onChangeFunction}
          name={input?.name}
        />
      </div>
    </div>
  )
}

SelectInput.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  labelField: PropTypes.string,
  valueField: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
}

export default SelectInput
