import React from 'react'
import {Form, Field} from 'react-final-form'
import formStyles from 'component/form/formFields.module.scss'
import DatePickerField from 'component/form/DatePickerField'
import TextInput from 'component/form/TextInput'
import FormRow from 'component/form/FormRow'
import cx from 'classnames'
import {connect} from 'react-redux'
import {editUserProfileWithKind} from 'redux/actions/user'
import {fireSuccessToast, getSelectValue} from 'helper/functions'
import {diff} from 'deep-object-diff'
import {composeValidators, required, validateEmail} from 'helper/validations'
import PropTypes from 'prop-types'
import SelectInput from 'component/form/SelectInput'
import {ADDRESS_NUMBER_KINDS, BANK_NUMBERS, DATABASE_DATE_FORMAT} from 'helper/constants'
import moment from 'moment'
import CheckboxInput from 'component/form/CheckBoxInput'

const MyAccountEditLegalForm = (props) => {
  const {profile, closeDialog, editUserProfileWithKind, kind} = props

  const datePickerClassNames = (values) => {
    return cx(formStyles.datePickerLabelWrapper, values?.person_birthday ? formStyles.notEmpty : '')
  }

  const prepareData = (values) => {
    const preparedValues = {
      ...values,
      person_birthday:
        values.person_birthday && moment(values.person_birthday).format(DATABASE_DATE_FORMAT),
      person_address_number_kind: getSelectValue(values, 'person_address_number_kind'),
      bank_code: getSelectValue(values, 'bank_code'),
    }
    return diff(profile, preparedValues)
  }

  const handleEditMyAccount = (values) => {
    const data = prepareData(values)
    editUserProfileWithKind(data, kind).then(() => {
      fireSuccessToast('Uživatelské údaje upraveny')
      closeDialog()
    })
  }

  const AddressNumberKindSelect = (props) => {
    return <SelectInput input={props.input} label={props.label} options={ADDRESS_NUMBER_KINDS} />
  }

  const BankCodeSelect = (props) => {
    return <SelectInput input={props.input} label={props.label} options={BANK_NUMBERS} />
  }

  return (
    <Form
      onSubmit={handleEditMyAccount}
      initialValues={profile}
      render={({handleSubmit, form, submitting, pristine, values}) => {
        return (
          <form onSubmit={handleSubmit} className={formStyles.form}>
            <FormRow>
              <div className={formStyles.col}>
                <Field name="legal_org_id" label="IČO" component={TextInput} validate={required} />
                <Field name="legal_tax_id" label="DIČ" component={TextInput} validate={required} />
              </div>
              <Field
                name="legal_name"
                label="Název subjektu"
                component={TextInput}
                validate={required}
              />
            </FormRow>

            <FormRow>
              <Field name="phone_mobile" label="Telefon (mobilní)" component={TextInput} />
              <Field name="phone_landline" label="Telefon (pevná linka)" component={TextInput} />
            </FormRow>

            <FormRow>
              <Field name="account_number" label="Bankovní účet" component={TextInput} />
              <Field name="bank_code" label="Kód banky" component={BankCodeSelect} />
            </FormRow>

            <FormRow>
              <Field name="legal_address" label="Adresa sídla" component={TextInput} />
              <Field
                name="email"
                label="E-mail"
                component={TextInput}
                validate={composeValidators(required, validateEmail)}
              />
            </FormRow>

            <FormRow>
              <Field
                type="checkbox"
                name={'legal_contact_address_similar'}
                label={'Doručovací adresa stejná jako trvalá adresa'}
                component={CheckboxInput}
              />
            </FormRow>

            {!values.legal_contact_address_similar && (
              <FormRow>
                <Field
                  name="legal_contact_address"
                  label="Doručovací adresa"
                  component={TextInput}
                />
              </FormRow>
            )}

            <div className={formStyles.divider}>Zástupce</div>

            <FormRow>
              <Field
                name="person_name_firstname"
                label="Jméno zástupce"
                component={TextInput}
                validate={required}
              />
              <Field
                name="person_name_lastname"
                label="Příjmení zástupce"
                component={TextInput}
                validate={required}
              />
            </FormRow>

            <FormRow>
              <div className={formStyles.col}>
                <Field name="person_name_title" label="Titul před jménem" component={TextInput} />
                <Field
                  name="person_name_title_after"
                  label="Titul za jménem"
                  component={TextInput}
                />
              </div>
              <div className={datePickerClassNames(values)}>
                <Field name="person_birthday" label="Datum narození" component={DatePickerField} />
              </div>
            </FormRow>

            <div className={formStyles.divider}>Adresa zástupce</div>

            <FormRow>
              <Field name="person_address_street" label="Ulice" component={TextInput} />
              <div className={formStyles.col}>
                <Field
                  name="person_address_number_kind"
                  label="Označení budovy"
                  component={AddressNumberKindSelect}
                />

                <Field
                  name="person_address_number"
                  label={
                    values && getSelectValue(values, 'person_address_number_kind')
                      ? ADDRESS_NUMBER_KINDS.find(
                          (kind) =>
                            kind.value === getSelectValue(values, 'person_address_number_kind')
                        ).addressNumberName
                      : 'Adresní číslo'
                  }
                  component={TextInput}
                />
              </div>
            </FormRow>

            <FormRow>
              <Field name="person_address_city" label="Město" component={TextInput} />
              <Field name="person_address_zipcode" label="PSČ" component={TextInput} />
            </FormRow>

            <div className={formStyles.changeText}>
              Změna, o kterou je žádáno, se stává účinnou, až po jejím potvrzení ze strany spol. VÁŠ DOMOVNÍK s.r.o. Do té doby platí údaje v jejich dřívější podobě.
              Na nově uvedené údaje, zejm. na jejich správu/nakládání s nimi, se vztahují stejná pravidla, která platila pro údaje, které mají být měněny.
            </div>

            <div className={formStyles.buttons}>
              <button className={formStyles.button} type="submit" disabled={submitting}>
                Zažádat o změnu
              </button>
              <button
                className={cx(formStyles.button, formStyles.cancelButton)}
                onClick={closeDialog}
                disabled={submitting}
              >
                Zrušit
              </button>
            </div>
          </form>
        )
      }}
    />
  )
}

MyAccountEditLegalForm.propTypes = {
  profile: PropTypes.object,
  kind: PropTypes.string,
  closeDialog: PropTypes.func,
  editUserProfileWithKind: PropTypes.func,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
  editUserProfileWithKind,
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountEditLegalForm)
