import endpoints from 'helper/endpoints'
import {globalApiErrorHandler, rethrow} from 'helper/functions'
import httpClient from 'helper/httpClient'
import {
  GET_IMPERSONATION_REQUEST,
  GET_IMPERSONATION_SUCCESS,
  GET_IMPERSONATION_FAIL,
} from 'redux/actionTypes'

export const getImpersonation = () =>
  function (dispatch) {
    dispatch({type: GET_IMPERSONATION_REQUEST})
    return httpClient
      .get(`${endpoints.impersonation}/clients`)
      .then((response) => {
        dispatch({
          type: GET_IMPERSONATION_SUCCESS,
          data: response.data,
        })
      })
      .catch(rethrow(() => dispatch({type: GET_IMPERSONATION_FAIL})))
      .catch(globalApiErrorHandler)
  }
