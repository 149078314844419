import {
  GET_CONDUCT_ACCOUNTINGS_REQUEST,
  GET_CONDUCT_ACCOUNTINGS_SUCCESS,
  GET_CONDUCT_ACCOUNTINGS_FAIL,
  PUT_CONDUCT_ACCOUNTINGS_REQUEST,
  PUT_CONDUCT_ACCOUNTINGS_SUCCESS,
  PUT_CONDUCT_ACCOUNTINGS_FAIL,
} from 'redux/actionTypes'
const initialState = {
  dataReceived: false,
  getInProgress: false,
  putInProgreess: false,
  data: [],
  meta: {},
}

const getConductAccountingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONDUCT_ACCOUNTINGS_REQUEST: {
      return {...state, getInProgress: true}
    }
    case GET_CONDUCT_ACCOUNTINGS_SUCCESS: {
      return {
        ...state,
        dataReceived: true,
        getInProgress: false,
        data: action.data.objects,
        meta: action.data.meta,
      }
    }
    case GET_CONDUCT_ACCOUNTINGS_FAIL: {
      return {...state, getInProgress: false}
    }
    case PUT_CONDUCT_ACCOUNTINGS_REQUEST: {
      return {...state, putInProgress: true}
    }
    case PUT_CONDUCT_ACCOUNTINGS_SUCCESS: {
      return state
    }
    case PUT_CONDUCT_ACCOUNTINGS_FAIL: {
      return {...state, putInProgress: false}
    }
    default: {
      return state
    }
  }
}

export default getConductAccountingsReducer
