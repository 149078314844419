import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import styles from './paymentsTable.module.scss'
import {getConductPayments} from 'redux/actions/conductPayments'
import {DATE_FORMAT} from 'helper/constants'
import Table from './Table'
import PaymentState from './PaymentState'
import Loader from 'component/Loader'
import NumberFormat from 'react-number-format'

const PaymentsTable = (props) => {
  const {
    conductId,
    leaseId,
    payments,
    getConductPayments,
    paymentsMeta,
    loading,
    dataReceived,
  } = props

  const [page, setPage] = useState(0)

  useEffect(() => {
    if (conductId && leaseId) {
      getConductPayments(conductId, leaseId, page)
    }
  }, [getConductPayments, conductId, leaseId, page])

  const fields = [
    {key: 'note_abbr', title: 'Položka', style: styles.firstColumn},
    {key: 'amount', title: 'Částka'},
    {key: 'maturity_date', title: 'Splatnost'},
    {key: 'state', title: 'Stav'},
    {key: 'outcome_date', title: 'Zasláno na Váš účet'},
  ]

  const data =
    payments &&
    payments.map((item) => {
      return {
        ...item,
        amount: (
          <NumberFormat
            thousandSeparator={' '}
            displayType="text"
            suffix={' Kč'}
            value={item.amount?.toLocaleString()}
          />
        ),
        maturity_date: item.maturity_date && moment(item.maturity_date).format(DATE_FORMAT),
        state: item?.state && <PaymentState state={item.state} />,
        outcome_date: item.outcome_date && moment(item.outcome_date).format(DATE_FORMAT),
        _key: item.id,
      }
    })

  const handleNextPage = () => {
    if (paymentsMeta.limit * (page + 1) < paymentsMeta.total_count) {
      setPage((prevState) => prevState + 1)
    }
  }

  const handlePageClick = (page) => {
    if (page > 0 || paymentsMeta.limit * (page + 1) < paymentsMeta.total_count) {
      setPage(page)
    }
  }

  const handlePreviousPage = () => {
    if (page > 0) {
      setPage((prevState) => prevState - 1)
    }
  }

  return (
    <div className={styles.PaymentsTable}>
      {loading && !dataReceived ? (
        <Loader />
      ) : (
        <Table
          fields={fields}
          data={data}
          pagination={true}
          page={page}
          maxPage={Math.ceil(paymentsMeta.total_count / paymentsMeta.limit)}
          nextPage={handleNextPage}
          prevPage={handlePreviousPage}
          goToPage={handlePageClick}
        />
      )}
    </div>
  )
}

PaymentsTable.propTypes = {
  conductId: PropTypes.number,
  leaseId: PropTypes.number,
  payments: PropTypes.array,
  getConductPayments: PropTypes.func,
  paymentsMeta: PropTypes.object,
  loading: PropTypes.bool,
  dataReceived: PropTypes.bool,
}

const mapStateToProps = (state) => {
  return {
    payments: state.conductPayments.data,
    paymentsMeta: state.conductPayments.meta,
    loading: state.conductPayments.getInProgress,
    dataReceived: state.conductPayments.dataReceived,
  }
}

const mapDispatchToProps = {
  getConductPayments,
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsTable)
