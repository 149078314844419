import React from 'react'
import {connect} from 'react-redux'
import {Link, useRouteMatch, useHistory} from 'react-router-dom'
import PropTypes from 'prop-types'
import styles from './detailPageMenu.module.scss'
import classNames from 'classnames/bind'
import {TABLET_SCREEN_WIDTH} from 'helper/constants'
import Select from 'react-dropdown-select'

const cx = classNames.bind(styles)

const DetailPageMenu = (props) => {
  const {activeLink} = props
  let routeMatch = useRouteMatch()
  let history = useHistory()

  const mobileView = window.innerWidth < TABLET_SCREEN_WIDTH

  const links = [
    {to: 'platby', text: 'Platby'},
    {to: 'smlouvy', text: 'Smlouvy'},
    {to: 'komunikace', text: 'Komunikace'},
    {to: 'dohlidky', text: 'Dohlídky'},
    {to: 'vyuctovani', text: 'Vyúčtování služeb'},
  ]

  const handleClickOnLink = (link) => {
    if (link[0]) {
      let urlParts = routeMatch.url.split('/')
      urlParts[urlParts.length - 1] = link[0].to
      history.push(urlParts.join('/'))
    } else {
      let urlParts = routeMatch.url.split('/')
      urlParts[urlParts.length - 1] = 'platby'
      history.push(urlParts.join('/'))
    }
  }

  return mobileView ? (
    <Select
      className="detail-page-select"
      values={[links.find((link) => link.to === activeLink)]}
      options={links}
      labelField={'text'}
      valueField={'to'}
      searchable={false}
      backspaceDelete={false}
      onChange={handleClickOnLink}
      dropdownGap={0}
    />
  ) : (
    <div className={styles.DetailPageMenu}>
      {links.map((link) => (
        <div key={link.to} className={cx('linkWrapper', {active: activeLink === link.to})}>
          <Link to={link.to} className={cx('link', {active: activeLink === link.to})}>
            {link.text}
          </Link>
        </div>
      ))}
    </div>
  )
}

DetailPageMenu.propTypes = {
  activeLink: PropTypes.string,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(DetailPageMenu)
