import React, {useState} from 'react'
import {animated, useTransition} from 'react-spring'
import classNames from 'classnames/bind'
import flaticon from 'asset/flaticon/flaticon.module.scss'
import styles from 'component/inspectionsTable/inspectionsTable.module.scss'
import {TABLET_SCREEN_WIDTH} from 'helper/constants'

const fi = classNames.bind(flaticon)

const Carousel = (props) => {
  const {carousel, closeImageCarousel} = props

  const [imageIndex, setImageIndex] = useState(carousel.currentIndex)
  const [direction, setDirection] = useState('forward')

  const mobileView = window.innerWidth < TABLET_SCREEN_WIDTH
  const animationMargin = mobileView ? 1000 : 2000

  const transitionsForward = useTransition(
    carousel.images?.length > 0 && carousel.images[imageIndex],
    (item) => item,
    {
      from: {opacity: 0.5, transform: `translateX(-${animationMargin}px)`},
      enter: {opacity: 1, transform: 'translateX(0px)'},
      leave: {opacity: 0.5, transform: `translateX(${animationMargin}px)`},
    }
  )

  const transitionsBack = useTransition(
    carousel.images?.length > 0 && carousel.images[imageIndex],
    (item) => item,
    {
      from: {opacity: 0.5, transform: `translateX(${animationMargin}px)`},
      enter: {opacity: 1, transform: 'translateX(0px)'},
      leave: {opacity: 0.5, transform: `translateX(-${animationMargin}px)`},
    }
  )

  const handleNext = (e) => {
    e.stopPropagation()
    if (carousel.images?.length - 1 > imageIndex) {
      setDirection('forward')
      setImageIndex((prevState) => prevState + 1)
    } else {
      setDirection('forward')
      setImageIndex(0)
    }
  }

  const handlePrevious = (e) => {
    e.stopPropagation()
    if (imageIndex - 1 >= 0) {
      setDirection('back')
      setImageIndex((prevState) => prevState - 1)
    } else {
      setDirection('back')
      setImageIndex(carousel.images?.length - 1)
    }
  }

  return (
    <div className={styles.carousel} onClick={closeImageCarousel()}>
      <div className={styles.wrapper}>
        <span
          onClick={handlePrevious}
          className={classNames(fi('flaticon-left-arrow'), styles.arrowButton, styles.left)}
        />
        {direction === 'forward'
          ? transitionsForward.map(({item, props, key}) => (
              <animated.div
                onClick={(e) => e.stopPropagation()}
                style={props}
                key={key}
                className={styles.carouselImages}
              >
                <img src={item.toString()} alt="" />
                <div className={styles.number}>
                  {`${imageIndex + 1} / ${carousel.images.length}`}
                </div>
              </animated.div>
            ))
          : transitionsBack.map(({item, props, key}) => (
              <animated.div
                onClick={(e) => e.stopPropagation()}
                style={props}
                key={key}
                className={styles.carouselImages}
              >
                <img src={item.toString()} alt="" />
                <div className={styles.number}>{`${imageIndex + 1} / ${
                  carousel.images.length
                }`}</div>
              </animated.div>
            ))}
        <span
          onClick={handleNext}
          className={classNames(fi('flaticon-right-arrow'), styles.arrowButton, styles.right)}
        />
      </div>
    </div>
  )
}

export default Carousel
