import React from 'react'
import DatePicker from 'react-datepicker'
// custom css copied from library in order to get the styles working
import 'component/form/react-datepicker.scss'
import moment from 'moment'
import formStyles from 'component/form/formFields.module.scss'
import PropTypes from 'prop-types'

const DatePickerField = (props) => {
  const {label, input} = props
  const getRange = (from, to) => {
    const range = []
    for (let i = from; i <= to; i++) {
      range.push(i)
    }
    return range
  }

  const years = getRange(1900, moment().year())

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  return (
    <>
      <label className={formStyles.label}>{label && label}</label>
      <DatePicker
        className={formStyles.dateField}
        dateFormat="dd.MM.yyyy"
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 10,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              {'<'}
            </button>
            <select value={moment(date).year()} onChange={({target: {value}}) => changeYear(value)}>
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[moment(date).month()]}
              onChange={({target: {value}}) => changeMonth(months.indexOf(value))}
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              {'>'}
            </button>
          </div>
        )}
        selected={input?.value && new Date(input?.value)}
        onChange={(date) => {
          input?.onChange(date ? date : null)
        }}
      />
    </>
  )
}

DatePickerField.propTypes = {
  label: PropTypes.string,
  input: PropTypes.object,
}

export default DatePickerField
