import endpoints from 'helper/endpoints'
import {globalApiErrorHandler, rethrow} from 'helper/functions'
import httpClient from 'helper/httpClient'
import {
  GET_CONDUCT_CONTRACTS_REQUEST,
  GET_CONDUCT_CONTRACTS_SUCCESS,
  GET_CONDUCT_CONTRACTS_FAIL,
} from 'redux/actionTypes'

export const getConductContracts = (conductId, leaseId, page) =>
  function (dispatch) {
    dispatch({type: GET_CONDUCT_CONTRACTS_REQUEST})

    const params = {
      limit: 20,
      offset: page * 20,
    }

    return httpClient
      .get(`${endpoints.conducts}/${conductId}/leases/${leaseId}/contracts`, params)
      .then((response) => {
        dispatch({
          type: GET_CONDUCT_CONTRACTS_SUCCESS,
          data: response.data,
        })
      })
      .catch(rethrow(() => dispatch({type: GET_CONDUCT_CONTRACTS_FAIL})))
      .catch(globalApiErrorHandler)
  }
