import React from 'react'
import {Form, Field} from 'react-final-form'
import formStyles from 'component/form/formFields.module.scss'
import cx from 'classnames'
import {connect} from 'react-redux'
import {editUserPassword} from 'redux/actions/user'
import {fireSuccessToast} from 'helper/functions'
import PasswordInput from 'component/form/PasswordInput'
import {required, samePasswordValidation} from 'helper/validations'
import PropTypes from 'prop-types'

const PasswordEditForm = (props) => {
  const {profile, closeDialog, editUserPassword} = props

  const handleEditPassword = (values) => {
    editUserPassword(values).then(() => {
      fireSuccessToast('Heslo bylo změněno')
      closeDialog()
    })
  }

  return (
    <Form
      onSubmit={handleEditPassword}
      initialValues={profile}
      validate={samePasswordValidation}
      render={({handleSubmit, form, submitting, pristine, values}) => (
        <form onSubmit={handleSubmit} className={formStyles.form}>
          <Field
            name="current_password"
            label="Stávající heslo"
            component={PasswordInput}
            validate={required}
          />
          <Field name="password" label="Nové heslo" component={PasswordInput} validate={required} />
          <Field
            name="password_confirmation"
            label="Nové heslo znovu"
            component={PasswordInput}
            validate={required}
          />

          <div className={formStyles.buttons}>
            <button className={formStyles.button} type="submit" disabled={submitting}>
              ULOŽIT
            </button>
            <button
              className={cx(formStyles.button, formStyles.cancelButton)}
              onClick={closeDialog}
              disabled={submitting}
            >
              ZRUŠIT
            </button>
          </div>
        </form>
      )}
    />
  )
}

PasswordEditForm.propTypes = {
  profile: PropTypes.object,
  closeDialog: PropTypes.func,
  editUserPassword: PropTypes.func,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
  editUserPassword,
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordEditForm)
