import endpoints from 'helper/endpoints'
import {globalApiErrorHandler} from 'helper/functions'
import httpClient from 'helper/httpClient'
import {
  SEND_DOPORUCIT_REQUEST,
  SEND_DOPORUCIT_SUCCESS,
  SEND_DOPORUCIT_FAIL,
} from 'redux/actionTypes'

export const sendDoporucitZnamemu = (data) =>
  function (dispatch) {
    dispatch({type: SEND_DOPORUCIT_REQUEST})
    return httpClient
      .post(endpoints.recommendations, data)
      .then((response) => {
        dispatch({
          type: SEND_DOPORUCIT_SUCCESS,
          data: response.data,
        })
        return Promise.resolve(response.data)
      })
      .catch((err) => {
        dispatch({type: SEND_DOPORUCIT_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err.response?.data?.errors)
      })
  }
