import AccountingsTable from 'component/AccountingsTable'
import CommunicationsTable from 'component/CommunicationsTable'
import ContractsTable from 'component/ContractsTable'
import DetailPageMenu from 'component/DetailPageMenu'
import LeaseDetail from 'component/LeaseDetail'
import PaymentsTable from 'component/PaymentsTable'
import {removeTrailingSlash} from 'helper/functions'
import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {Switch, Route, useParams, useRouteMatch, Redirect} from 'react-router-dom'
import {getConductDetail} from 'redux/actions/conducts'
import styles from './detailPage.module.scss'
import InspectionsTable from 'component/inspectionsTable/InspectionsTable'
import YourPropertyManager from 'component/yourPropertyManager/YourPropertyManager'
import {getUserProfileManager} from 'redux/actions/user'
import {getLeases} from 'redux/actions/conductLease'

const DetailPage = (props) => {
  const {
    getConductDetail,
    conductReqState,
    getUserProfileManager,
    manager,
    getLeases,
    leaseDetail,
    activeLease,
    leases,
  } = props
  const conductId = Number(useParams().conductId)
  let routeMatch = useRouteMatch()

  useEffect(() => {
    getConductDetail(conductId)
    getLeases(conductId)
    getUserProfileManager()
    window.scroll({top: 0, left: 0, behavior: 'smooth'})
  }, [getConductDetail, getUserProfileManager])

  const leaseId =
    leaseDetail?.id && leases.some((lease) => lease.id === leaseDetail.id)
      ? leaseDetail.id
      : activeLease?.id
      ? activeLease.id
      : null

  const leaseToDisplay =
    leaseDetail?.id && leases.some((lease) => lease.id === leaseDetail.id)
      ? leaseDetail
      : activeLease || {}

  return (
    <div className={styles.DetailPage}>
      <div className={styles.topPartBackground}>
        <div className={styles.topPart}>
          {!conductReqState.inProgress &&
            conductReqState.id === conductId &&
            (!conductReqState.succeeded ? (
              <Redirect to="/404" />
            ) : (
              <LeaseDetail lease={leaseToDisplay} />
            ))}
          <YourPropertyManager manager={manager && manager} />
        </div>
      </div>
      <div>
        <Switch>
          <Route path={`${routeMatch.path}/platby`}>
            <DetailPageMenu activeLink="platby" />
            <div className={styles.scrollTable}>
              <PaymentsTable conductId={conductId} leaseId={leaseId} />
            </div>
          </Route>
          <Route path={`${routeMatch.path}/smlouvy`}>
            <DetailPageMenu activeLink="smlouvy" />
            <div className={styles.scrollTable}>
              <ContractsTable conductId={conductId} leaseId={leaseId} />
            </div>
          </Route>
          <Route path={`${routeMatch.path}/komunikace`}>
            <DetailPageMenu activeLink="komunikace" />
            <div className={styles.scrollTable}>
              <CommunicationsTable conductId={conductId} leaseId={leaseId} />
            </div>
          </Route>
          <Route path={`${routeMatch.path}/dohlidky`}>
            <DetailPageMenu activeLink="dohlidky" />
            <div className={styles.scrollTable}>
              <InspectionsTable conductId={conductId} leaseId={leaseId} />
            </div>
          </Route>
          <Route path={`${routeMatch.path}/vyuctovani`}>
            <DetailPageMenu activeLink="vyuctovani" />
            <div className={styles.scrollTable}>
              <AccountingsTable conductId={conductId} leaseId={leaseId} />
            </div>
          </Route>
          <Route exact path={routeMatch.path}>
            <Redirect to={`${removeTrailingSlash(routeMatch.url)}/platby`} />
          </Route>
        </Switch>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    leaseDetail: state.conductLease.detail,
    leases: state.conductLease.data,
    activeLease: state.conducts.detail.active_lease,
    conductReqState: state.conducts.getDetailReqState,
    manager: state.user.manager,
  }
}

const mapDispatchToProps = {
  getConductDetail,
  getUserProfileManager,
  getLeases,
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailPage)
