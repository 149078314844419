import React from 'react'
import PropTypes from 'prop-types'

import styles from './paymentsState.module.scss'

const PaymentState = (props) => {
  const stateMapper = {
    paid: (
      <>
        <span className={styles.circleGreen}>●</span>&nbsp;Uhrazeno
      </>
    ),
    not_paid: (
      <>
        <span className={styles.circleRed}>●</span>&nbsp;Neuhrazeno
      </>
    ),
    waiting: (
      <>
        <span className={styles.circleOrange}>●</span>&nbsp;Čeká se na banku
      </>
    ),
    created: (
      <>
        <span className={styles.circleOrange}>●</span>&nbsp;Čeká se na banku
      </>
    ),
    satisfied: (
      <>
        <span className={styles.circleOrange}>●</span>&nbsp;Uhrazeno z jistoty
      </>
    ),
    underpaid: (
      <>
        <span className={styles.circleRed}>●</span>&nbsp;Částečně uhrazeno
      </>
    ),
    canceled: (
      <>
        <span className={styles.circleGray}>●</span>&nbsp;Zrušeno
      </>
    ),
  }
  return stateMapper[props.state] || null
}

PaymentState.propTypes = {
  state: PropTypes.string,
}

export default PaymentState
