import {
  GET_CONDUCT_INSPECTIONS_FAIL,
  GET_CONDUCT_INSPECTIONS_REQUEST,
  GET_CONDUCT_INSPECTIONS_SUCCESS,
} from 'redux/actionTypes'
const initialState = {dataReceived: false, getInProgress: false, data: [], meta: {}}

const getConductInspectionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONDUCT_INSPECTIONS_REQUEST: {
      return {...state, getInProgress: true}
    }
    case GET_CONDUCT_INSPECTIONS_SUCCESS: {
      return {
        ...state,
        dataReceived: true,
        getInProgress: false,
        data: action.data.objects,
        meta: action.data.meta,
      }
    }
    case GET_CONDUCT_INSPECTIONS_FAIL: {
      return {...state, getInProgress: false}
    }
    default: {
      return state
    }
  }
}

export default getConductInspectionsReducer
