import React, {useState} from 'react'
import styles from 'page/recommendPage.module.scss'
import Dialog from 'component/Dialog/Dialog'
import DoporucitZNamenuForm from 'component/doporucitZnamenu/DoporucitZnamenuForm'
import recommendImage from 'asset/img/VASDOMOVNIK_doporucit_img.png'
import {ReactComponent as Check} from 'asset/img/check_circle.svg'

const RecommendPage = () => {
  const [recommendDialogVisible, setRecommendDialogVisible] = useState(false)
  const [messageSendVisible, setMessageSendVisible] = useState(false)

  const handleVisibleRecommendDialog = () => {
    setRecommendDialogVisible((prevState) => !prevState)
  }

  const onSuccessSend = () => {
    setMessageSendVisible(true)
  }

  const handleCloseMessageSent = () => {
    setRecommendDialogVisible(false)
    setMessageSendVisible(false)
  }

  return (
    <div className={styles.RecommendPage}>
      {recommendDialogVisible && (
        <Dialog
          title={!messageSendVisible ? 'Předat kontakt' : ''}
          handleClose={!messageSendVisible ? handleVisibleRecommendDialog : handleCloseMessageSent}
        >
          {!messageSendVisible ? (
            <div className={styles.doporucitZnamemu}>
              <DoporucitZNamenuForm onSend={onSuccessSend} />
            </div>
          ) : (
            <div className={styles.messageSent}>
              <Check />
              <div className={styles.title}>Zpráva odeslána</div>
              <p>
                Děkujeme, že myslíte na ostatní a jako malou pozornost za Vaše úsilí, od nás,
                prosíme, přijiměte pozvání (poukaz) na skvělou výběrovou kávu do kavárny Snílek v
                Plzni. Vychutnejte si ji s Vaším partnerem/partnerkou nebo třeba s tím, komu chcete
                v pronajímání pomoci.
              </p>
              <button className={styles.recommendButton} onClick={handleCloseMessageSent}>
                ZAVŘÍT
              </button>
            </div>
          )}
        </Dialog>
      )}

      <div className={styles.topPartBackground}>
        <div className={styles.topPart}>Doporučit známého</div>
      </div>
      <div className={styles.recommendPageContent}>
        <div className={styles.text}>
          <p>
            Víte-li o někom, kdo by potřeboval pomoc s pronajímáním, budeme Vám vděčni za sdílení
            kontaktu. Doporučení vždy bylo a je tou nejlepší formou zpětné vazby, kterou nám jako
            naši spokojení klienti můžete dát.
          </p>
        </div>
        <button className={styles.recommendButton} onClick={handleVisibleRecommendDialog}>
          PŘEDAT KONTAKT
        </button>
        <div className={styles.image}>
          <img src={recommendImage} alt="Doporučit" />
        </div>
      </div>
    </div>
  )
}

export default RecommendPage
